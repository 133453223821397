'use client';

import { useEffect, useState } from 'react';

import { calculatePrice } from '@bytel/product-wall/helpers';
import {
    Alignable,
    Columns,
    ColumnsItem,
    Divider,
    Spacer,
    SpacerSize,
    Text,
    TextLevels,
    TypographyAlign,
    TypographyBold,
} from '@bytel/trilogy-react-ts';

import { getApiProductDetails } from '@services/products';

import { DiscountEnumType } from '@app-types/discount';
import { Product, ProductChildFull } from '@app-types/product';

import { Price } from '@components/price';

import { getDiscountValue } from '@helpers/product';

type PriceDetailProps = {
    product: Product | ProductChildFull;
    isTaxExcluded?: boolean;
};

export function PriceDetailAccessory({ product, isTaxExcluded = false }: PriceDetailProps) {
    const [file, setFile] = useState<string>();
    useEffect(() => {
        if (product.id) {
            getApiProductDetails(product.id)
                .then((result) => {
                    setFile(result.odr?.file);
                })
                .catch((error) => {
                    console.log('error', error);
                });
        }
    }, [product]);

    if (!product.details || !product.id) return null;

    const discounts = product.details.discounts;
    const instantDiscount = product.details.price.initial - product.details.price.final;

    const sumODRDiscount = getDiscountValue(discounts, DiscountEnumType.ODR);

    return (
        <>
            <Columns mobile>
                <ColumnsItem size={7} verticalCenter className='has-text-left'>
                    <Text>
                        {product.brand} {product.name}
                    </Text>
                </ColumnsItem>
                <ColumnsItem size={5} className='has-text-right'>
                    <Price
                        align={Alignable.ALIGNED_END}
                        amount={calculatePrice(product.details.price.initial, isTaxExcluded)}
                        mention={isTaxExcluded ? 'HT' : ''}
                        className='font-inherit'
                    />
                </ColumnsItem>
            </Columns>
            {instantDiscount > 0 && (
                <Columns mobile>
                    <ColumnsItem size={7} verticalCenter className='has-text-left'>
                        <Text>Remise immédiate</Text>
                    </ColumnsItem>
                    <ColumnsItem size={5} className='has-text-right'>
                        <Price
                            align={Alignable.ALIGNED_END}
                            amount={calculatePrice(-instantDiscount, isTaxExcluded)}
                            mention={isTaxExcluded ? 'HT' : ''}
                            className='font-inherit'
                        />
                    </ColumnsItem>
                </Columns>
            )}
            {sumODRDiscount > 0 && (
                <Columns mobile>
                    <ColumnsItem size={9} verticalCenter className='has-text-left'>
                        <div>
                            <Text marginless typo={TypographyAlign.TEXT_LEFT}>
                                Remboursement après achat
                            </Text>
                            {file ? (
                                <Text level={TextLevels.THREE} typo={TypographyAlign.TEXT_LEFT} marginless>
                                    <a className='link' download href={file}>
                                        Télécharger le coupon
                                    </a>
                                </Text>
                            ) : (
                                <Spacer size={SpacerSize.MEDIUM} />
                            )}
                        </div>
                    </ColumnsItem>
                    <ColumnsItem size={3} className='has-text-right'>
                        <Price
                            align={Alignable.ALIGNED_END}
                            amount={calculatePrice(-sumODRDiscount, isTaxExcluded)}
                            mention={isTaxExcluded ? 'HT' : ''}
                            className='font-inherit'
                        />
                    </ColumnsItem>
                </Columns>
            )}
            {(sumODRDiscount > 0 || instantDiscount > 0) && (
                <>
                    <Divider />
                    <Columns mobile>
                        <ColumnsItem size={8} verticalCenter className='has-text-left'>
                            <Text level={TextLevels.ONE} marginless typo={TypographyBold.TEXT_WEIGHT_SEMIBOLD}>
                                Prix après remboursement
                            </Text>
                        </ColumnsItem>
                        <ColumnsItem size={4} className='has-text-right'>
                            =&nbsp;
                            <Price
                                className='is-size-5 has-text-weight-semibold'
                                level={3}
                                amount={calculatePrice(product.details.price.final - sumODRDiscount, isTaxExcluded)}
                                mention={isTaxExcluded ? 'HT' : ''}
                            />
                        </ColumnsItem>
                    </Columns>
                </>
            )}
        </>
    );
}
