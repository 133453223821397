'use client';

import { calculatePrice } from '@bytel/product-wall/helpers';
import {
    Alignable,
    Columns,
    ColumnsItem,
    Divider,
    Icon,
    IconName,
    Popover,
    Text,
    TextLevels,
    TypographyBold,
    View,
} from '@bytel/trilogy-react-ts';

import { Price } from '@components/price';

type PriceDetailProps = {
    value: number;
    isTaxExcluded?: boolean;
};

export function PriceDetailToday({ value, isTaxExcluded = false }: PriceDetailProps) {
    return (
        <Columns mobile>
            <ColumnsItem size={9} verticalCenter className='has-text-left'>
                <View flexable>
                    <Text marginless>Remboursement après achat</Text>
                    <Popover content='Le coupon et les instructions vous seront envoyés par mail après achat.'>
                        <Icon name={IconName.INFOS_CIRCLE} className='popover-modal' />
                    </Popover>
                </View>
            </ColumnsItem>
            <ColumnsItem size={3} className='has-text-right'>
                <Price
                    align={Alignable.ALIGNED_END}
                    amount={calculatePrice(-(value < 0 ? 1 : value), isTaxExcluded)}
                    mention={isTaxExcluded ? 'HT' : ''}
                    className='font-inherit'
                />
            </ColumnsItem>
        </Columns>
    );
}

export function PriceTotalToday({ value, isTaxExcluded = false }: PriceDetailProps) {
    return (
        <>
            <Divider />
            <Columns mobile>
                <ColumnsItem size={8} verticalCenter className='has-text-left'>
                    <Text level={TextLevels.ONE} marginless typo={TypographyBold.TEXT_WEIGHT_SEMIBOLD}>
                        Prix après remboursement
                    </Text>
                </ColumnsItem>
                <ColumnsItem size={4} className='has-text-right'>
                    =&nbsp;
                    <Price
                        className='is-size-5 has-text-weight-semibold'
                        level={3}
                        amount={calculatePrice(value, isTaxExcluded)}
                        mention={isTaxExcluded ? 'HT' : ''}
                    />
                </ColumnsItem>
            </Columns>
        </>
    );
}
