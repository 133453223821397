import { ActiveFiltersType } from '@bytel/product-wall';
import { countActiveFilters } from '@bytel/product-wall/helpers';

import { getAppConfig } from '@services/config';
import { cmsHttpService } from '@services/http';
import { CACHE_TAGS } from '@services/http/http';

import { WallConfigType } from '@app-types/config';

const appConfig = getAppConfig();

export const getSlider = async (
    wallConfig: WallConfigType,
    activeFilters: ActiveFiltersType = {},
    renewal: boolean = false,
): Promise<string | undefined> => {
    const genericSliderUrl: string = 'tous-les-telephones';
    let url = genericSliderUrl;

    if (countActiveFilters(activeFilters) === 1) {
        const [key, value] = Object.entries(activeFilters)[0] as [string, string[]];
        const urlKey = Object.values(wallConfig.filtersObject[key]?.values ?? {}).find(
            (v) => v.value === value[0],
        )?.key;
        url = urlKey ?? genericSliderUrl;
    }

    let sliderContent: string = '';

    try {
        sliderContent = await sliderApi(url, renewal);
    } catch (e) {
        if (url === genericSliderUrl) {
            return;
        }

        await sliderApi(genericSliderUrl, renewal)
            .then((result) => {
                sliderContent = result;
            })
            .catch(() => {});
    }

    return sliderContent;
};

const sliderApi = async (url: string, renewal = false): Promise<string> => {
    const suffix: string = renewal ? '/banners/mdt-renouv' : '/banners/mdt';
    const baseUrl = `${appConfig.assets.cms.url}${suffix}`;

    return cmsHttpService.get<string>(`${baseUrl}-${url}`, undefined, {
        ttl: parseInt(getAppConfig().cache.ttl.cms),
        tags: [CACHE_TAGS.CMS],
    });
};
