'use client';

import Image from 'next/image';

import { calculatePrice } from '@bytel/product-wall/helpers';
import {
    Alignable,
    Columns,
    ColumnsItem,
    Spacer,
    SpacerSize,
    Text,
    TextLevels,
    TypographyAlign,
    View,
} from '@bytel/trilogy-react-ts';

import { DiscountEnumType } from '@app-types/discount';
import { FundingEnumType, FundingType } from '@app-types/funding';
import { PriceFundingsDetails } from '@app-types/product';

import { Price } from '@components/price';
import { PriceDetailToday, PriceTotalToday } from '@components/product-details-modal';

import { getDiscountValue } from '@helpers/product';

import younitedIcon from '@assets/logo-younited.svg';

type FundingProps = {
    productDetails: PriceFundingsDetails;
    isTaxExcluded?: boolean;
    funding?: FundingType;
};

export function Funding({ productDetails: { discounts, price }, isTaxExcluded = false, funding }: FundingProps) {
    const sumODRDiscount = getDiscountValue(discounts, DiscountEnumType.ODR);

    if (!funding && sumODRDiscount > 0) {
        return (
            <>
                <PriceDetailToday isTaxExcluded={isTaxExcluded} value={sumODRDiscount} />
                <PriceTotalToday value={price.final - sumODRDiscount} isTaxExcluded={isTaxExcluded} />
            </>
        );
    } else if (!funding) {
        return null;
    }

    return (
        <>
            {funding.type !== FundingEnumType.COMPTANT && (
                <Columns mobile>
                    <ColumnsItem size={7} verticalCenter className='has-text-left'>
                        <Text>À régler aujourd&apos;hui</Text>
                    </ColumnsItem>
                    <ColumnsItem size={5} className='has-text-right'>
                        <Price
                            align={Alignable.ALIGNED_END}
                            amount={calculatePrice(funding.initialDeposit, isTaxExcluded)}
                            mention={isTaxExcluded ? 'HT' : ''}
                            className='font-inherit'
                        />
                    </ColumnsItem>
                </Columns>
            )}
            {!funding.type.toUpperCase().includes(FundingEnumType.YOUNITED) && sumODRDiscount > 0 && (
                <PriceDetailToday isTaxExcluded={isTaxExcluded} value={sumODRDiscount} />
            )}
            {(funding.type === FundingEnumType.EDP ||
                funding.type.toUpperCase().includes(FundingEnumType.YOUNITED)) && (
                <Columns mobile>
                    <ColumnsItem size={6} verticalCenter className='has-text-left'>
                        <div>
                            <Text marginless>Puis à payer par mois</Text>
                            <Text level={TextLevels.THREE} typo={TypographyAlign.TEXT_LEFT} marginless>
                                {funding.type === FundingEnumType.EDP ? 'Facilité ' : 'Financement'} sur{' '}
                                {funding.installmentCount} mois
                                {funding.type !== FundingEnumType.EDP ? '*' : ''}
                                {isTaxExcluded ? ' (non soumis à la TVA)' : ''}
                            </Text>
                        </div>
                    </ColumnsItem>
                    <ColumnsItem size={6} className='has-text-right'>
                        <Price
                            align={Alignable.ALIGNED_END}
                            amount={funding.monthlyAmount}
                            period='mois'
                            className='font-inherit'
                        />
                    </ColumnsItem>
                </Columns>
            )}
            {funding.type.toUpperCase().includes(FundingEnumType.YOUNITED) && sumODRDiscount > 0 && (
                <PriceDetailToday isTaxExcluded={isTaxExcluded} value={sumODRDiscount} />
            )}
            {sumODRDiscount > 0 && (
                <PriceTotalToday
                    value={
                        funding.type.toUpperCase().includes(FundingEnumType.YOUNITED)
                            ? price.final - sumODRDiscount + funding.interestAmount
                            : price.final - sumODRDiscount
                    }
                    isTaxExcluded={isTaxExcluded}
                />
            )}
            {funding.type.toUpperCase().includes(FundingEnumType.YOUNITED) && (
                <>
                    <Spacer size={SpacerSize.MEDIUM} />
                    <View className='subtable has-text-left has-background-grey-lighter column card is-shadowless'>
                        <Text level={TextLevels.THREE} className='is-flex' marginless>
                            *Financement &nbsp; <Image src={younitedIcon} alt='Younited' width={70} />
                        </Text>
                        <Text level={TextLevels.THREE} marginless>
                            Intérêts : {funding.interestAmount}€ / Taux annuel effectif global (TAEG) fixe :{' '}
                            {funding.APR}%
                            {funding.fixedInterestRate && funding.fixedInterestRate >= 0 && (
                                <>&nbsp;/ Taux débiteur fixe :&nbsp;{funding.fixedInterestRate}%</>
                            )}
                        </Text>
                        <Text level={TextLevels.THREE}>
                            Un crédit vous engage et doit être remboursé. Vérifiez vos capacités de remboursement avant
                            de vous engager.
                        </Text>
                    </View>
                </>
            )}
        </>
    );
}
