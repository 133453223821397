import { Headline, HeadlineLevel, Modal, Spacer, SpacerSize, TypographyAlign } from '@bytel/trilogy-react-ts';

import { WallType } from '@app-types/config';
import { FundingType } from '@app-types/funding';
import { PlanDetailWithProvenanceType } from '@app-types/plan';
import { Product, ProductChildFull, ProductType } from '@app-types/product';

import { Funding, PriceDetailAccessory, PriceDetailPhone } from '@components/product-details-modal';

type ProductDetailModalProps = {
    onClose: () => void;
    type: WallType | ProductType;
    plan?: Partial<PlanDetailWithProvenanceType>;
    isTaxExcluded: boolean;
    selectedFunding?: FundingType;
    modalOpen: boolean;
    currentProduct?: Product | ProductChildFull;
};

export function ProductDetailsModal({
    onClose,
    type,
    plan,
    isTaxExcluded,
    selectedFunding,
    modalOpen,
    currentProduct,
}: ProductDetailModalProps) {
    if (!currentProduct?.details) return null;

    return (
        <Modal active={modalOpen} onClose={onClose} closeIcon>
            <Headline level={HeadlineLevel.LEVEL3} typo={TypographyAlign.TEXT_LEFT} className='is-size-3'>
                Détail du prix
            </Headline>
            {(type === ProductType.PHONE || type === WallType.PHONE) && (
                <>
                    <PriceDetailPhone
                        plan={plan}
                        initialPrice={currentProduct.details.price.initial}
                        finalPrice={currentProduct.details.price.final}
                        subsidizedPrice={currentProduct.details.price.subsidized}
                        isTaxExcluded={isTaxExcluded}
                    />
                    <Spacer size={SpacerSize.MEDIUM} />
                    {currentProduct.details && (
                        <Funding
                            funding={selectedFunding}
                            productDetails={currentProduct.details}
                            isTaxExcluded={isTaxExcluded}
                        />
                    )}
                </>
            )}
            {(type === ProductType.ACCESSORY || type === WallType.ACCESSORY) && (
                <PriceDetailAccessory product={currentProduct} isTaxExcluded={isTaxExcluded} />
            )}
        </Modal>
    );
}
