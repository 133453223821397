import { getAppConfig } from '@services/config';
import { cmsHttpService } from '@services/http';
import { CACHE_TAGS } from '@services/http/http';

const appConf = getAppConfig();
export const getProductSpecifications = async (product: string): Promise<string | undefined> => {
    return cmsHttpService
        .get<string | undefined>(`${appConf.assets.cms.url}/products/${product}/recap`, undefined, {
            ttl: parseInt(getAppConfig().cache.ttl.cms),
            tags: [CACHE_TAGS.CMS],
        })
        .catch(() => undefined);
};

export const getProductsHighlights = async (product: string): Promise<string | undefined> => {
    return cmsHttpService
        .get<string | undefined>(`${appConf.assets.cms.url}/products/${product}/description`, undefined, {
            ttl: parseInt(getAppConfig().cache.ttl.cms),
            tags: [CACHE_TAGS.CMS],
        })
        .catch(() => undefined);
};

export const getProductsSpecialOffers = async (product: string, isRenewal: boolean): Promise<string | undefined> => {
    return cmsHttpService
        .get<string | undefined>(
            `${appConf.assets.cms.url}/products/${product}/offres-speciales/${isRenewal ? 'rnw' : 'acq'}`,
            undefined,
            {
                ttl: parseInt(getAppConfig().cache.ttl.cms),
                tags: [CACHE_TAGS.CMS],
            },
        )
        .catch((error) => {
            console.error(`Fetch ${product} special offers cms failed`, error);
            return undefined;
        });
};
