'use client';

import { useEffect } from 'react';

import konami from '@bytel/konami';

type KonamiProps = {
    action: () => void;
    code?: string[];
};

export function Konami({ action, code }: KonamiProps) {
    useEffect(() => {
        const konamiHandler = konami(action, code);

        return () => {
            document.removeEventListener('keydown', konamiHandler);
        };
    }, []);

    return null;
}
