import { getAppConfig } from '@services/config';
import { cmsHttpService } from '@services/http';
import { CACHE_TAGS } from '@services/http/http';

import { PlanType } from '@app-types/plan';

import { PlanTypeId } from '@constants/plan';

const appConf = getAppConfig();

export const getPlanPopInCms = async (
    isRenew: boolean,
    isPro: boolean,
    plan: PlanType,
): Promise<string | undefined> => {
    if (!plan.dataEnvelope) {
        return undefined;
    }

    let planType: string;

    switch (plan.typeId) {
        case PlanTypeId.IDEO:
            planType = 'ideo';
            break;
        case PlanTypeId.IDEO_PRO:
            planType = 'ideo_pro';
            break;
        case PlanTypeId.SOWO:
        case PlanTypeId.SOWO_RENEWAL:
        case PlanTypeId.SOWO_SPECIALS:
            planType = plan.obligation ? 'simo' : 'byou';
            break;
        default:
            planType = 'sas';
            break;
    }

    const popInCmsUrl = `${isRenew ? 'renouvellement/' : ''}${isPro ? 'pro/' : ''}${planType}/popin-${plan.dataEnvelope
        ?.replace(/ /g, '_')
        ?.replace(/à/g, 'a')
        ?.toLowerCase()}`;

    return cmsHttpService
        .get<string | undefined>(`${appConf.assets.cms.url}/mobiles/${popInCmsUrl}`, undefined, {
            ttl: parseInt(getAppConfig().cache.ttl.cms),
            tags: [CACHE_TAGS.CMS],
        })
        .catch((error) => {
            console.error(`Fetch plan popin cms failed`, error);
            return undefined;
        });
};

export const getInternationalCallsCms = async (isPro: boolean): Promise<string | undefined> => {
    return cmsHttpService
        .get<string | undefined>(
            `${appConf.assets.cms.url}/mobiles/banniere_forfaits-mobiles_destinations${isPro ? '_pro' : ''}`,
            undefined,
            {
                ttl: parseInt(getAppConfig().cache.ttl.cms),
                tags: [CACHE_TAGS.CMS],
            },
        )
        .catch((error) => {
            console.error(`Fetch plan international calls cms failed`, error);
            return undefined;
        });
};
