import { ActiveFiltersType } from '@bytel/product-wall';
import { countActiveFilters } from '@bytel/product-wall/helpers';

import { getAppConfig } from '@services/config';
import { cmsHttpService } from '@services/http';
import { CACHE_TAGS } from '@services/http/http';

import { WallConfigType } from '@app-types/config';

export const getAccordionSeo = async (
    wallConfig: WallConfigType,
    activeFilters: ActiveFiltersType = {},
    renewal: boolean = false,
): Promise<string | undefined> => {
    const genericAccordionUrl: string = 'tous-les-telephones';
    let url = genericAccordionUrl;

    if (countActiveFilters(activeFilters) === 1) {
        const [key, value] = Object.entries(activeFilters)[0] as [string, string[]];
        const urlKey = Object.values(wallConfig.filtersObject[key]?.values ?? {}).find(
            (v) => v.value === value[0],
        )?.key;
        url = urlKey ?? genericAccordionUrl;
    }

    let accordionContent: string | undefined;

    accordionContent = await accordionSeoApi(url, renewal);

    if (!accordionContent) {
        accordionContent = await accordionSeoApi(genericAccordionUrl, renewal);
    }

    return accordionContent;
};

const accordionSeoApi = async (url: string, renewal = false): Promise<string | undefined> => {
    const appConf = getAppConfig();
    const suffix: string = renewal ? '/mobiles/mur-produits/seo/renouvellement' : '/mobiles/mur-produits/seo';
    const baseUrl = `${appConf.assets.cms.url}${suffix}`;

    return cmsHttpService
        .get<string | undefined>(`${baseUrl}/${url}`, undefined, {
            ttl: parseInt(getAppConfig().cache.ttl.cms),
            tags: [CACHE_TAGS.CMS],
        })
        .catch(() => undefined);
};
