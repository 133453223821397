'use client';

import { calculatePrice } from '@bytel/product-wall/helpers';
import {
    Alignable,
    Columns,
    ColumnsItem,
    Divider,
    Text,
    TextLevels,
    TypographyAlign,
    TypographyBold,
} from '@bytel/trilogy-react-ts';

import { PlanDetailWithProvenanceType } from '@app-types/plan';

import { Price } from '@components/price';

type PriceDetailProps = {
    plan?: Partial<PlanDetailWithProvenanceType>;
    initialPrice: number;
    finalPrice: number;
    subsidizedPrice?: number;
    isTaxExcluded?: boolean;
};

export function PriceDetailPhone({
    plan,
    initialPrice,
    finalPrice,
    subsidizedPrice,
    isTaxExcluded = false,
}: PriceDetailProps) {
    const discountWithPlan = subsidizedPrice && plan ? initialPrice - subsidizedPrice : 0;
    const instantDiscount = (subsidizedPrice || initialPrice) - finalPrice;
    return (
        <>
            {discountWithPlan + instantDiscount > 0 && (
                <Columns mobile>
                    <ColumnsItem size={7} verticalCenter className='has-text-left'>
                        <Text>Prix seul</Text>
                    </ColumnsItem>
                    <ColumnsItem size={5} className='has-text-right'>
                        <Price
                            align={Alignable.ALIGNED_END}
                            amount={calculatePrice(initialPrice, isTaxExcluded)}
                            mention={isTaxExcluded ? 'HT' : ''}
                            className='font-inherit'
                        />
                    </ColumnsItem>
                </Columns>
            )}
            {plan && discountWithPlan > 0 && (
                <Columns mobile>
                    <ColumnsItem size={8} verticalCenter className='has-text-left'>
                        <div>
                            <Text marginless typo={TypographyAlign.TEXT_LEFT}>
                                Remise avec un forfait de {plan.dataEnvelope}
                            </Text>
                            <Text
                                marginless
                                level={TextLevels.THREE}
                                typo={TypographyAlign.TEXT_LEFT}
                                className='capitalize-first'
                            >
                                {plan.obligationLabel}
                            </Text>
                        </div>
                    </ColumnsItem>
                    <ColumnsItem size={4} className='has-text-right'>
                        <Price
                            align={Alignable.ALIGNED_END}
                            amount={calculatePrice(-discountWithPlan, isTaxExcluded)}
                            mention={isTaxExcluded ? 'HT' : ''}
                            className='font-inherit'
                        />
                    </ColumnsItem>
                </Columns>
            )}
            {instantDiscount > 0 && (
                <Columns mobile>
                    <ColumnsItem size={7} verticalCenter className='has-text-left'>
                        <Text>Remise immédiate</Text>
                    </ColumnsItem>
                    <ColumnsItem size={5} className='has-text-right'>
                        <Price
                            align={Alignable.ALIGNED_END}
                            amount={calculatePrice(-instantDiscount, isTaxExcluded)}
                            mention={isTaxExcluded ? 'HT' : ''}
                            className='font-inherit'
                        />
                    </ColumnsItem>
                </Columns>
            )}
            {discountWithPlan + instantDiscount > 0 && <Divider />}
            <Columns mobile>
                <ColumnsItem size={8} verticalCenter className='has-text-left'>
                    <Text level={TextLevels.ONE} marginless typo={TypographyBold.TEXT_WEIGHT_SEMIBOLD}>
                        Prix du téléphone
                    </Text>
                </ColumnsItem>
                <ColumnsItem size={4} className='has-text-right has-text-weight-bold'>
                    =&nbsp;
                    <Price
                        className='is-size-5'
                        level={3}
                        amount={calculatePrice(finalPrice ?? 0, isTaxExcluded)}
                        mention={isTaxExcluded ? 'HT' : ''}
                    />
                </ColumnsItem>
            </Columns>
        </>
    );
}
